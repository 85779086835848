@font-face {
  font-family: myFont;
  src: url('../fonts/HelveticaNeue.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: myFont;
  src: url('../fonts/HelveticaNeue-Light.otf');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: myFont;
  src: url('../fonts/HelveticaNeue-Bold.otf');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: myFont;
  src: url('../fonts/HelveticaNeue-Medium.otf');
  font-weight: 500;
  font-style: normal;
}